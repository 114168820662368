import React from "react";
import SubEnrollmentLessons from "./SubEnrollmentLessons";
import SubEnrollmentOnlineLessons from "./SubEnrollmentOnlineLessons";

const SelectNewSchoolEnroll = ({
    data,
    handleChange,
    addSchoolType,
    setAddSchoolType,
    handleClick,
    getSchools,
    getSchoolGrades,
    getSchoolInstruments,
    handleInstrumentClick,
    getSchoolInstrumentsPrograms,
    InstrumentTemplate,
    ActionTemplate,
    visible,
    setVisible,
    enrollInstrument,
    handleEnroll,
    index,
    getOnlineGrades,
    getOnlineLessons,
    gradeIntruments,
    getAllInstruments,
    viewOnlineLesson,
}) => {
    return (
        <>
            <div className=" relative mb-3">
                <h5 className="text-dark font-bold mb-2">
                    Enrollment information for Lessons <span className="text-main">At</span> Your School or Online
                </h5>

                <div className="grid">
                    <div className="col-12 md:col-5 lg:col-4">
                        <p
                            className={`text-center open-sans font-semibold border-set border-round-lg p-3 text-base w-100 enrollBtn cursor-pointer ${
                                addSchoolType === "school_lessons" ? "active" : ""
                            }`}
                            onClick={() => {
                                handleClick("school_lessons");
                            }}
                        >
                            Enrollment Info - at School Lessons
                        </p>
                    </div>
                    <div className="col-12 md:col-5 lg:col-4">
                        <p
                            className={`text-center open-sans font-semibold border-set border-round-lg p-3 text-base w-100 enrollBtn cursor-pointer ${
                                addSchoolType === "online_lessons" ? "active" : ""
                            }`}
                            onClick={() => {
                                handleClick("online_lessons");
                            }}
                        >
                            Enrollment info - Private or Online Lesson
                        </p>
                    </div>
                </div>
                <div className="p-error">{data?.formErrors?.enroll_type}</div>
                {addSchoolType === "school_lessons" ? (
                    <>
                        <SubEnrollmentLessons
                            data={data}
                            handleChange={handleChange}
                            addSchoolType={addSchoolType}
                            setAddSchoolType={setAddSchoolType}
                            handleClick={handleClick}
                            getSchools={getSchools}
                            getSchoolGrades={getSchoolGrades}
                            getSchoolInstruments={getSchoolInstruments}
                            handleInstrumentClick={handleInstrumentClick}
                            getSchoolInstrumentsPrograms={getSchoolInstrumentsPrograms}
                            InstrumentTemplate={InstrumentTemplate}
                            ActionTemplate={ActionTemplate}
                            visible={visible}
                            setVisible={setVisible}
                            enrollInstrument={enrollInstrument}
                            handleEnroll={handleEnroll}
                            index={index}
                        />
                    </>
                ) : addSchoolType === "online_lessons" ? (
                    <SubEnrollmentOnlineLessons
                        data={data}
                        handleChange={handleChange}
                        getOnlineGrades={getOnlineGrades}
                        getOnlineLessons={getOnlineLessons}
                        gradeIntruments={gradeIntruments}
                        getAllInstruments={getAllInstruments}
                        viewOnlineLesson={viewOnlineLesson}
                        addSchoolType={addSchoolType}
                        setAddSchoolType={setAddSchoolType}
                        handleClick={handleClick}
                        getSchools={getSchools}
                        getSchoolGrades={getSchoolGrades}
                        getSchoolInstruments={getSchoolInstruments}
                        handleInstrumentClick={handleInstrumentClick}
                        getSchoolInstrumentsPrograms={getSchoolInstrumentsPrograms}
                        InstrumentTemplate={InstrumentTemplate}
                        ActionTemplate={ActionTemplate}
                        visible={visible}
                        setVisible={setVisible}
                        enrollInstrument={enrollInstrument}
                        handleEnroll={handleEnroll}
                        index={index}
                    />
                ) : null}
                {/* {addSchoolType === "online_lessons" && (
                    <>
                        <div className="border-round-lg ms-3 relative z-3 bg-white">
                            <form action="">
                                <p className="text-dark-purple text-xs mb-2 font-normal open-sans">Select School</p>
                                <DropDownComponent
                                    name="online_school"
                                    className="w-full mb-2"
                                    placeholder="Select Lesson Programs"
                                    value={selectedOnlineLessons?.online_school}
                                    options={onlineLessonProgram?.map((item, index) => {
                                        return {
                                            label: item?.name,
                                            value: item?.id,
                                        };
                                    })}
                                    onChange={handleChange}
                                />
                            </form>
                        </div>
                        {err && <div className="p-error">{err.school}</div>}
                        {selectedOnlineLessons?.online_school && (
                            <OnlineSchoolDetail viewOnlineSchoolDetail={viewOnlineSchoolDetail} />
                        )}
                    </>
                )} */}
            </div>
        </>
    );
};

export default SelectNewSchoolEnroll;
